import styled from "styled-components";
import spacing from "styles/spacing";
import layout from "styles/layout";
import screenSize from "styles/mediaQueries";
import { fontFamilyBrand, TextH3, TextSmallP } from "styles/typography";
import * as DefaultSeparator from "@radix-ui/react-separator";

export const Separator = styled(DefaultSeparator.Root)`
  height: 1px;
  width: 100%;
  background-color: var(--color-inputOutline);
  margin: 1.25rem 0;
`;

export const Footer = styled.footer`
  position: relative;
  background: var(--color-background);
  overflow: hidden;
`;

export const TitleText = styled(TextH3)`
  margin-top: ${spacing.lg};
  margin-bottom: 0;
  font-family: ${fontFamilyBrand};
  color: var(--color-text);
  text-align: center;
`;

export const YearText = styled(TextSmallP)`
  font-family: ${fontFamilyBrand};
  color: var(--color-darkAccent);
  margin: 0;
  margin-bottom: ${spacing.sm};
  text-align: center;
`;

export const SocialIcons = styled.div`
  text-align: center;
  margin: ${spacing.md} 0;
`;

export const IconLink = styled.a`
  margin-right: ${spacing.xs};
  :last-child {
    margin-right: 0;
  }
`;

export const SocialIcon = styled.img`
  width: 1.5rem;
`;

export const FooterGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  row-gap: ${spacing.lg};
  column-gap: ${spacing.xs};

  ${screenSize.minTablet`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `}
`;

export const FooterLink = styled.a`
  text-decoration: underline;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  padding: 0 0 ${spacing.xs} 0;
  display: block;
`;

const baseStyles = `
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-height: 90%;
  height: 30%;
  width: 100%;
  pointer-events: none;
`;

export const SkylineContainerMobile = styled.div`
  ${baseStyles}
  max-width: ${layout.backgroundMaxWidth};
  ${screenSize.minTablet`
    display: none;
  `}
`;

export const SkylineContainer = styled.div`
  ${baseStyles}
  display: none;
  max-width: ${layout.backgroundMaxWidth};

  ${screenSize.minTablet`
    display: block;
    bottom: -4px;
    height: auto;
  `}
`;

export const FooterSpecial = styled.div`
  height: 12.5rem;
  width: 100%;
`;
