import * as React from "react";
import { DEFAULT_SITE_METADATA } from "utils/constants";
import QueensGuard from "components/ui/QueensGuard";
import ConditionallyRender from "components/ui/ConditionallyRender";
import LocaleSwitcher from "components/ui/LocaleSwitcher";
import { TextSmallP } from "styles/typography";
import { Content, PaddedContainer } from "components/ui/Layout";
import NextImage from "next/legacy/image";
import Link from "next/link";

import * as Styled from "./Footer.styled";

import LondonSkyline from "../../../../public/images/london_skyline.png";
import LondonSkylineMobile from "../../../../public/images/london_skyline_mobile.png";
import facebookLogo from "../../../../public/images/facebook.svg";

function Footer(props) {
  const { showLondonSkyline = false } = props;

  return (
    <Styled.Footer>
      <PaddedContainer>
        <Content>
          <Styled.TitleText>{DEFAULT_SITE_METADATA.title}</Styled.TitleText>
          <Styled.YearText>{new Date().getFullYear()}</Styled.YearText>

          <Styled.FooterGrid>
            {/* Teaching Resources */}
            <div>
              <TextSmallP>
                <strong>Teaching</strong>
              </TextSmallP>
              <Link href="/teaching" passHref legacyBehavior>
                <Styled.FooterLink>
                  <TextSmallP>Teaching Home</TextSmallP>
                </Styled.FooterLink>
              </Link>
              <Link href="/teaching/shop" passHref legacyBehavior>
                <Styled.FooterLink>
                  <TextSmallP>Shop</TextSmallP>
                </Styled.FooterLink>
              </Link>
              <Link href="/order" passHref legacyBehavior>
                <Styled.FooterLink>
                  <TextSmallP>Your orders</TextSmallP>
                </Styled.FooterLink>
              </Link>
              <Link href="/teaching/terms-of-use" passHref legacyBehavior>
                <Styled.FooterLink>
                  <TextSmallP>Terms of use</TextSmallP>
                </Styled.FooterLink>
              </Link>
              <Link href="/teaching/faq" passHref legacyBehavior>
                <Styled.FooterLink>
                  <TextSmallP>FAQ</TextSmallP>
                </Styled.FooterLink>
              </Link>
              <Link
                href="/teaching/email-signup"
                passHref
                legacyBehavior
              >
                <Styled.FooterLink>
                  <TextSmallP>Subscribe to our email list!</TextSmallP>
                </Styled.FooterLink>
              </Link>
            </div>

            <div>
              <TextSmallP>
                <strong>More teaching</strong>
              </TextSmallP>
              <Link href="/blog/category/teaching" passHref legacyBehavior>
                <Styled.FooterLink>
                  <TextSmallP>Blog</TextSmallP>
                </Styled.FooterLink>
              </Link>
              <Link href="/teaching/guide" passHref legacyBehavior>
                <Styled.FooterLink>
                  <TextSmallP>Guides</TextSmallP>
                </Styled.FooterLink>
              </Link>
              <Link href="/teaching/reference" passHref legacyBehavior>
                <Styled.FooterLink>
                  <TextSmallP>Reference material</TextSmallP>
                </Styled.FooterLink>
              </Link>
              <Link href="/teaching/tools" passHref legacyBehavior>
                <Styled.FooterLink>
                  <TextSmallP>Tools (Worksheet generators)</TextSmallP>
                </Styled.FooterLink>
              </Link>

              <Styled.Separator style={{ width: "75%" }} />

              <TextSmallP>
                <strong>Freebies!</strong>
              </TextSmallP>
              <Link
                href="/teaching/free-lesson-planning-templates"
                passHref
                legacyBehavior
              >
                <Styled.FooterLink>
                  <TextSmallP>Lesson planning templates</TextSmallP>
                </Styled.FooterLink>
              </Link>
              <Link
                href="/teaching/fractions-cheat-sheets"
                passHref
                legacyBehavior
              >
                <Styled.FooterLink>
                  <TextSmallP>Fractions cheat sheets</TextSmallP>
                </Styled.FooterLink>
              </Link>
              <Link
                href="/teaching/well-being-ideas-and-activities"
                passHref
                legacyBehavior
              >
                <Styled.FooterLink>
                  <TextSmallP>Wellbeing ideas and activities</TextSmallP>
                </Styled.FooterLink>
              </Link>
            </div>

            {/* About */}
            <div>
              <TextSmallP>
                <strong>at the minute</strong>
              </TextSmallP>
              <Link href="/blog" passHref legacyBehavior>
                <Styled.FooterLink>
                  <TextSmallP>General blog</TextSmallP>
                </Styled.FooterLink>
              </Link>
              <Link href="/about" passHref legacyBehavior>
                <Styled.FooterLink>
                  <TextSmallP>About</TextSmallP>
                </Styled.FooterLink>
              </Link>
              <Link href="/contact" passHref legacyBehavior>
                <Styled.FooterLink>
                  <TextSmallP>Contact Us</TextSmallP>
                </Styled.FooterLink>
              </Link>
              <Link href="/privacy-policy" passHref legacyBehavior>
                <Styled.FooterLink>
                  <TextSmallP>Privacy Policy</TextSmallP>
                </Styled.FooterLink>
              </Link>
            </div>

            {/* Tools */}
            <div>
              <TextSmallP>
                <strong>Location Setting</strong>
              </TextSmallP>
              <LocaleSwitcher />

              <Styled.Separator style={{ width: "75%" }} />

              <TextSmallP style={{ marginTop: "0.625rem" }}>
                <strong>Misc Tools</strong>
              </TextSmallP>
              <Link href="/itch-bundle-explorer/" passHref legacyBehavior>
                <Styled.FooterLink>
                  <TextSmallP>Itch Bundle Explorer</TextSmallP>
                </Styled.FooterLink>
              </Link>
              <Link href="/vapid-key-generator/" passHref legacyBehavior>
                <Styled.FooterLink>
                  <TextSmallP>Vapid Key Generator</TextSmallP>
                </Styled.FooterLink>
              </Link>
            </div>
          </Styled.FooterGrid>

          <Styled.SocialIcons>
            <Styled.IconLink
              href="https://www.facebook.com/atm.teaching"
              target="_blank"
              rel="noreferrer"
            >
              <Styled.SocialIcon
                width={24}
                height={24}
                src={facebookLogo.src}
                alt="Facebook Logo"
              />
            </Styled.IconLink>
          </Styled.SocialIcons>
        </Content>
      </PaddedContainer>

      {showLondonSkyline ? (
        <Styled.FooterSpecial>
          <Styled.SkylineContainer>
            <NextImage
              src={LondonSkyline.src}
              alt="London Skyline"
              layout="responsive"
              width="1200"
              height="177"
              unoptimized
            />
          </Styled.SkylineContainer>
          <Styled.SkylineContainerMobile>
            <NextImage
              src={LondonSkylineMobile.src}
              alt="London Skyline"
              layout="responsive"
              width="630"
              height="203"
              unoptimized
            />
          </Styled.SkylineContainerMobile>

          <ConditionallyRender client>
            <QueensGuard />
          </ConditionallyRender>
        </Styled.FooterSpecial>
      ) : null}
    </Styled.Footer>
  );
}

export default Footer;
