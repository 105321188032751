/* eslint-disable react/require-default-props */
import * as React from 'react';
import PropTypes from 'prop-types';
import walkLeft from './queens_guard_walking_left.png';
import walkRight from './queens_guard_walking_right.png';
import idle from './queens_guard_idle.png';

// import walkLeft from './queens_guard_walking_left_scaled-min.png';
// import walkRight from './walking_teaching_right.png';
// import idle from './walking_teaching_idle.png';

import * as Styled from './Sprite.styled';

// const TOTAL_FRAMES = 8;

export const FRAME_WIDTH = 75;
const FRAME_HEIGHT = 150;

let currentFrame = 0;

const SPEED = 20;

const SPRITE_STATES = {
  WALK_LEFT: { xFrameStart: 0, xFrameEnd: 7, duration: 500, image: walkLeft.src },
  WALK_RIGHT: { xFrameStart: 0, xFrameEnd: 7, duration: 500, image: walkRight.src },
  IDLE: { xFrameStart: 0, xFrameEnd: 0, duration: 300, image: idle.src },
};

export default function Sprite(props) {
  const preloadImage = url => {
    const img = new Image();
    img.src = url;
    return img;
  };

  const preloadedImages = React.useRef([]);
  const { spriteState, initialXPosition, maxXPosition, minXPosition } = props;
  const framesInCurrentState =
    SPRITE_STATES[spriteState].xFrameEnd -
    SPRITE_STATES[spriteState].xFrameStart +
    1;

  const [xOffset, setXOffset] = React.useState(0);
  const [xPosition, setXPosition] = React.useState(initialXPosition || 0);

  const { duration, image } = SPRITE_STATES[spriteState];

  // Animation
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (currentFrame >= framesInCurrentState - 1) {
        currentFrame = 0;
      } else {
        currentFrame += 1;
      }
      const literalFrameNumber =
        currentFrame + (SPRITE_STATES[spriteState].xFrameStart - 1);
      setXOffset(-literalFrameNumber * FRAME_WIDTH);
    }, duration / framesInCurrentState);

    return () => {
      clearInterval(interval);
      setXOffset(-3 * FRAME_WIDTH);
    };
  }, [spriteState]);

  // Movement
  React.useEffect(() => {
    const update = () => {
      if (spriteState === 'WALK_LEFT') {
        setXPosition(value =>
          value - SPEED < minXPosition ? minXPosition : value - SPEED
        );
      } else if (spriteState === 'WALK_RIGHT') {
        setXPosition(value =>
          value + SPEED > maxXPosition ? maxXPosition : value + SPEED
        );
      }
    };
    update();
    const interval = setInterval(update, 100);
    return () => {
      clearInterval(interval);
    };
  }, [spriteState]);

  React.useEffect(() => {
    preloadedImages.current.push(preloadImage(idle.src));
    preloadedImages.current.push(preloadImage(walkRight.src));
    preloadedImages.current.push(preloadImage(walkLeft.src));
  }, []);

  return (
    <Styled.Sprite
      style={{
        position: 'absolute',
        backgroundImage: `url('${image}')`,
        backgroundPositionX: `${xOffset}px`,
        backgroundPositionY: '0px',
        backgroundSize: `${framesInCurrentState * 100}% 100%`, // TOTAL_FRAMES when using one sprite sheet
        height: `${FRAME_HEIGHT}px`,
        width: `${FRAME_WIDTH}px`,
        left: `${xPosition}px`,
      }}
    />
  );
}

Sprite.propTypes = {
  spriteState: PropTypes.string,
  initialXPosition: PropTypes.number,
  maxXPosition: PropTypes.number,
  minXPosition: PropTypes.number,
};
