import * as React from "react";
import useKeyPress from "hooks/useKeyPress";
import { getRandomInt } from "utils";

import Sprite, { FRAME_WIDTH } from "./Sprite";
import * as Styled from "./QueensGuard.styled";
import ConditionallyRender from "../ConditionallyRender";

const globalMinDuration = 0;
const globalMaxDuration = 10000;

const initialState = "IDLE";
const spriteStateParameters = {
  WALK_LEFT: { maxDuration: 2500, minDuration: 300 },
  WALK_RIGHT: { maxDuration: 2500, minDuration: 300 },
  IDLE: { maxDuration: 2000, minDuration: 300 },
};

const useWalkingAI = (isEnabled) => {
  const [spriteState, setSpriteState] = React.useState(initialState);
  const isEnabledRef = React.useRef(isEnabled);

  const chooseNextState = (currentState) => {
    const nextPossibleStates = Object.keys(spriteStateParameters).filter(
      (k) => k !== currentState
    );
    const nextState =
      nextPossibleStates[getRandomInt(nextPossibleStates.length)];

    // For 0-100%
    const nextStateMinDuration =
      spriteStateParameters[nextState].minDuration || globalMinDuration;
    const nextStateMaxDuration =
      spriteStateParameters[nextState].maxDuration || globalMaxDuration;
    const randomPercent = getRandomInt(101) / 100;

    // Get random duration between min and max. ASSUMES MAX > MIN.
    const nextStateDuration =
      (nextStateMaxDuration - nextStateMinDuration) * randomPercent +
      nextStateMinDuration;

    setSpriteState(nextState);
    if (isEnabledRef.current) {
      setTimeout(() => chooseNextState(nextState), nextStateDuration);
    }
  };

  React.useEffect(() => {
    isEnabledRef.current = isEnabled;
    chooseNextState();
  }, [isEnabled]);

  return spriteState;
};

function QueensGuard() {
  const isAIControlled = React.useRef(true);

  const spriteStateAI = useWalkingAI(isAIControlled.current);
  const rightPress = useKeyPress("ArrowRight");
  const leftPress = useKeyPress("ArrowLeft");

  const max = window.innerWidth - FRAME_WIDTH;
  const randomInitial = getRandomInt(max);

  // If user has interacted set AIcontrolled to no
  const userHasInteracted = rightPress || leftPress;
  if (userHasInteracted) {
    isAIControlled.current = false;
  }

  let spriteState;

  // If AI controlled use from AI otherwise use keyboard.
  if (isAIControlled.current) {
    spriteState = spriteStateAI;
  } else if (rightPress) {
    spriteState = "WALK_RIGHT";
  } else if (leftPress) {
    spriteState = "WALK_LEFT";
  } else {
    spriteState = "IDLE";
  }

  return (
    <Styled.SpriteWrapper>
      <Sprite
        minXPosition={0}
        maxXPosition={max}
        initialXPosition={randomInitial}
        spriteState={spriteState}
      />
    </Styled.SpriteWrapper>
  );
}

export default function QueensGuardClientOnly(props) {
  return (
    <ConditionallyRender client>
      <QueensGuard {...props} />
    </ConditionallyRender>
  );
}
