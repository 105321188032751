export const IS_PREVIEW_ENV = process.env.PREVIEW_MODE === 'true';

const DEFAULT_META_DESCRIPTION =
  "Two kiwis living in London working on creating a bank of free and purchasable teaching resources and blogging about it along the way.";

export const DEFAULT_SITE_METADATA = {
  displayName: 'At the Minute',
  title: 'at the minute',
  description: DEFAULT_META_DESCRIPTION,
  author: 'Katherine Chung & Simon du Preez',
  siteUrl: 'https://www.attheminute.com',
};
