import { css } from "styled-components";
import { ColourName, colourNames } from "./colours";

export interface ClampLineStylesProps {
  $backgroundColorName?: ColourName;
}

export const clampLineStyles = (numLines: number) => css<ClampLineStylesProps>`
  /* Ellipsis styles */
  position: relative;
  max-height: calc(1.6em * ${numLines});
  overflow: hidden;
  padding-right: 0.625rem;

  :after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40%;
    height: 1.6em;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      var(
          --color-${(props) => props.$backgroundColorName || colourNames.BACKGROUND}
        )
        50%
    );
  }
`;
