import styled from 'styled-components';
import spacing from 'styles/spacing';
import screenSize from 'styles/mediaQueries';

// eslint-disable-next-line
export const SpriteWrapper = styled.div`
  position: absolute;
  bottom: ${spacing.sm};
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  pointer-events: none;

  ${screenSize.minDesktop`
    bottom: ${spacing.md};
  `}
`;
