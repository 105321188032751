import { prependStringIfNotExists } from './index';

const isStoryblokURL = (url) => {
  return url.indexOf('storyblok.com') !== -1;
}

// Given: https://myurl.com/
// Returns: //myurl.com/
export const stripHttp = url => {
  let result = url.replace('https:', '');
  result = result.replace('http:', '');

  return result;
};

export const maxWidthStoryblokImage = (imageUrl, maxWidth) => {
  if (!imageUrl) return '';
  if (!isStoryblokURL(imageUrl)) return imageUrl;

  return prependStringIfNotExists('https:', stripHttp(`${imageUrl}/m/${maxWidth}x0`));
}

export const dimensionsFromStoryblokURL = (imageUrl) => {
  if (!imageUrl) return undefined;
  if (!isStoryblokURL(imageUrl)) return undefined;

  const width = imageUrl.split('/')[5].split('x')[0];
  const height = imageUrl.split('/')[5].split('x')[1];
  return {
    width: width && parseInt(width),
    height:  height && parseInt(height),
  }
}

export const storyblokLoader = ({ src, width, quality=80 }) => {
  if (!isStoryblokURL(src)) return src;
  if (src.includes('/m/')) {
    return `${src}/filters:quality(${quality})`;
  }
  return `${src}/m/${width}x0/filters:quality(${quality})`
}


