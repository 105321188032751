import { Menu, MenuItem, MenuButton } from "components/ui/ReactMenu";
import { useRouter } from "next/router";
import { TextLabel } from "styles/typography";
import { SUPPORTED_LOCALES } from "utils/localisation";

const flagMap: { [key: string]: string } = {
  default: "nz",
  us: "us",
  gb: "gb",
  nz: "nz",
  au: "au",
  ca: "ca",
  za: "za",
};

function Flag(props: { locale?: string }) {
  const flag =
    props.locale && flagMap[props.locale] ? flagMap[props.locale] : "nz";

  return (
    <picture>
      <source
        type="image/webp"
        srcSet={`https://flagcdn.com/24x18/${flag}.webp,
      https://flagcdn.com/48x36/${flag}.webp 2x,
      https://flagcdn.com/72x54/${flag}.webp 3x`}
      />
      <source
        type="image/png"
        srcSet={`https://flagcdn.com/24x18/${flag}.png,
      https://flagcdn.com/48x36/${flag}.png 2x,
      https://flagcdn.com/72x54/${flag}.png 3x`}
      />
      <img
        src={`https://flagcdn.com/32x24/${flag}.png`}
        width="24"
        height="18"
        alt=""
      />
    </picture>
  );
}

interface LocaleSwitcherProps {
  style: any;
}

export default function LocaleSwitcher(props: LocaleSwitcherProps) {
  const { style } = props;
  const router = useRouter();
  const { pathname, asPath, query, locale } = router;

  const switchLocale = (locale: string) => {
    router.push({ pathname, query }, asPath, { locale });
  };

  return (
    <Menu
      menuButton={
        <MenuButton style={{ padding: "0.3125rem", ...style }}>
          {<Flag locale={locale} />}
        </MenuButton>
      }
      transition
    >
      {Object.keys(SUPPORTED_LOCALES).map((key) => {
        return (
          <MenuItem
            key={key}
            onClick={() => switchLocale(key)}
            style={{ padding: "0.125rem 0.3125rem" }}
          >
            <Flag locale={key} />
            <TextLabel style={{ marginLeft: "0.3125rem" }}>
              {SUPPORTED_LOCALES[key]}
            </TextLabel>
          </MenuItem>
        );
      })}
    </Menu>
  );
}
