import styled from 'styled-components';
import screenSize from 'styles/mediaQueries';
import spacing from 'styles/spacing';
import { TextSmallP } from 'styles/typography';
import {
  Menu as DefaultMenu,
  MenuItem as DefaultMenuItem,
  MenuButton as DefaultMenuButton,
  SubMenu as DefaultSubMenu,
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import Link from 'next/link';

export const Menu = styled(DefaultMenu)`
  background: var(--color-background);
  
  .szh-menu {
    background: var(--color-background);
  }
`;

export const MenuItem = styled(DefaultMenuItem)`
  padding: 0;
  margin: 0;
  background: var(--color-background);
  :hover {
    background: var(--color-backgroundHover);
  }
`;

export const SubMenu = styled(DefaultSubMenu)`
  background: var(--color-background);
`;

export const MenuButton = styled(DefaultMenuButton)`
  border: none;
  background: var(--color-background);
  color: var(--color-text);

  :hover {
    background: var(--color-backgroundHover);
  }
`;

export const MenuText = styled(TextSmallP)`
  margin: 0;
  color: var(--color-text);

  padding: ${spacing.xs} ${spacing.sm};

  ${screenSize.minTablet`
    padding: ${spacing.xxs} ${spacing.sm};
  `}
`;

export const RootMenuLink = styled(Link)`
  text-decoration: none;
  margin-right: ${spacing.md};
`;

export const MenuLink = styled(Link)`
  text-decoration: none;
  width: 100%;
  padding: 0;
  margin: 0;
`;
