// replacePageSlugLocale:: (String, String) -> String
export const replacePageSlugLocale = (fullSlug, replaceLocale) =>
  fullSlug.replace(
    /^(\/?)([a-z]*|[a-z]*-[a-z]*)(\/.*)$/i,
    (match, matchSlash, matchLocale, matchRemainingSlug) =>
      `${matchSlash || ''}${replaceLocale}${matchRemainingSlug}`
  );

export default null;

export const prependStringIfNotExists = (prefix, string) => {
  if (string.substr(0, prefix.length) !== prefix) {
    return `${prefix}${string}`;
  }
  return string;
};

export const appendStringIfNotExists = (suffix, string) => {
  if (string.substr(string.length - suffix.length, string.length) !== suffix) {
    return `${string}${suffix}`;
  }
  return string;
};

export const getYoutubeIdFromYoutubeUrl = youtubeUrl => {
  // Case 1: String is the Id itself and no parsing is required. E.g: V3BqyibFZC0
  if (youtubeUrl.indexOf('/') === -1) {
    return youtubeUrl;
  }

  // Case 2: YouTube video URL, e.g: https://youtu.be/V3BqyibFZC0
  const caseTwo = 'youtu.be/';
  const caseTwoIndex = youtubeUrl.indexOf(caseTwo);
  if (caseTwoIndex !== -1) {
    return youtubeUrl.substring(caseTwoIndex + caseTwo.length).split('?')[0];
  }

  // Case 3: YouTube 'watch' URL, e.g: https://www.youtube.com/watch?v=V3BqyibFZC0
  const caseThree = 'youtube.com/watch';
  const caseThreeIndex = youtubeUrl.indexOf(caseThree);
  if (caseThreeIndex !== -1) {
    return youtubeUrl
      .substring(caseThreeIndex + caseThree.length)
      .split('?')[1]
      .split('&')
      .find(queryString => queryString.indexOf('v=') !== -1)
      .split('=')[1];
  }

  // No match
  return null;
};

// Returns between 0 and max NOT including max.s
export const getRandomInt = max => Math.floor(Math.random() * Math.floor(max));

export const formatDateStringShort = (dateString) => {
  if (!dateString) return '';

  const publishedDate = new Date(dateString);
  const day = publishedDate.getDate();
  const month = publishedDate.toLocaleString('default', { month: 'short' });
  const year = publishedDate.getFullYear();

  Intl.form

  return `${day} ${month}, ${year}`;
}
